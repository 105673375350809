<template>
  <div>
    <div v-if="!hasTeams && !mappedList.length">
      <EmptyState :createTeam="createTeam" />
    </div>
    <div v-else>
      <p class="font-barlow text-mdl font-bold text-grey-light mb-2">Teams</p>
      <div class="flex flex-row items-end justify-between">
        <div>
          <InputField placeholder="Search by team, manager or employee" label="Filter by" :onChange="setKeyword"
            leftIcon class="mb-2 w-inputField" />
        </div>
        <div>
          <div class="mb-2">
            <Button text="+ New Team" type="primary" size="medium" @click.native="createTeam" />
          </div>
        </div>
      </div>
      <p class="text-sm-2 text-grey-dark-2 mb-2">
        Showing {{ mappedList.length }} teams out of {{ totalCount }}
      </p>
      <div>
        <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded"
          :class="mappedList.length <= 7 ? '' : 'pr-2'">
          <div class="flex flex-row items-center focus:outline-none w-1/20">
            <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
          </div>
          <div class="flex w-1/3 xl:w-1/2">
            <button class="flex flex-row items-center focus:outline-none" @click="() => sortCol('NAME', 0)">
              <p class="text-sm-2 text-grey-light mr-0.4">TEAM NAME</p>
              <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
                :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null" />
            </button>
          </div>
          <div class="flex flex-row w-1/4 xl:w-1/3">
            <button class="flex flex-row items-center focus:outline-none" @click="sortCol('MANAGER', 1)">
              <p class="text-sm-2 text-grey-light mr-0.4">MANAGER</p>
              <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
                :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null" />
            </button>
          </div>
          <div class="flex flex-row justify-center w-1/4 xl:w-1/6">
            <button class="flex flex-row items-center focus:outline-none" @click="sortCol('MEMBERS', 2)">
              <p class="text-sm-2 text-grey-light mr-0.4">MEMBERS</p>
              <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
                :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null" />
            </button>
          </div>
          <div class="flex flex-row w-1/5">
            <button class="flex flex-row items-center focus:outline-none" @click="sortCol('STATUS', 3)">
              <p class="text-sm-2 text-grey-light mr-0.4">STATUS</p>
              <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
                :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null" />
            </button>
          </div>
          <button class="flex flex-row justify-center w-1/5 focus:outline-none">
            <p class="text-sm-2 text-grey-light mr-0.4">ACTIONS</p>
          </button>
        </div>
        <div>
          <VirtualList style="max-height: 392px; overflow-y: auto" :data-key="'name'" :data-sources="mappedList"
            :data-component="itemComponent" :extra-props="{ manage: doManage }" @tobottom="triggerFetch" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import debounce from "lodash/debounce";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import EmptyState from "./EmptyState";
import cleanUpSpaces from '@/services/utils/cleanUpSpaces.js';

export default {
  name: "TeamTable",
  components: { VirtualList, EmptyState },
  data: () => ({
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    filteredList: [],
    pageNr: 1,
    pageSz: 20,
    totlaCount: 1,
    sorting: null,
    hasTeams: true,
    checkTeamsFor0State: true,
  }),
  mounted() {
    this.updateList();
  },
  computed: {
    ...mapState({
      teams: (state) => state.adminTeams.teams,
      totalCount: (state) => state.adminTeams.totalCount,
    }),
  },
  watch: {
    teams(value) {
      if (this.checkTeamsFor0State) {
        this.hasTeams = !!value.length;
        this.checkTeamsFor0State = false;
      }
    }
  },
  methods: {
    ...mapActions({
      getTeams: "adminTeams/getTeams",
      openModal: "modals/openModal",
    }),
    doManage(id) {
      this.$router.push(`/admin-teams/${id}`);
    },
    createTeam() {
      this.openModal({
        modal: "createTeamModal",
        props: { callback: this.updateList },
      });
    },
    sortCol(col, idx) {
      this.resetRotationMatrix(idx);
      switch (col) {
        case "NAME":
          this.sorting = { name: this.rotationMatrix[idx] == -1 ? 1 : 2 };
          break;
        case "MANAGER":
          this.sorting = { manager: this.rotationMatrix[idx] == -1 ? 1 : 2 };
          break;
        case "MEMBERS":
          this.sorting = { members: this.rotationMatrix[idx] == -1 ? 1 : 2 };
          break;
        case "STATUS":
          this.sorting = { status: this.rotationMatrix[idx] == -1 ? 1 : 2 };
          break;
        default:
          break;
      }
      this.updateList(true)
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? (array[index] *= -1) : (array[index] = 1)
      );
    },
    setKeyword: debounce(function (value) {
      this.keyword = value;
      this.updateList(true);
    }, 500),
    async updateList(reset) {
      if (reset) { this.mappedList = []; this.pageNr = 1 }
      await this.getTeams({
        onlyActive: false,
        pageSz: this.pageSz,
        pageNr: this.pageNr,
        sorting: this.sorting,
        keyword: this.keyword,
      });
      const newItems = this.teams.map((item, idx) => ({
        ...item,
        isSelected: false,
        index: (this.pageNr - 1) * this.pageSz + idx,
      }));

      this.mappedList = [...this.mappedList, ...newItems];
    },

    triggerFetch() {
      if (this.pageNr < Math.ceil(this.totalCount / this.pageSz)) {
        this.pageNr++;
        this.updateList();
      }
    },
  },
};
</script>
