import {
  fetchOngoingAssessments,
  fetchClosedAssessments,
  fetchOngoingAdminAssessments,
} from "@/services/api/dashboard";
import { fetchCompanyAssessments } from "@/services/api/companyResults";
import flatten from "lodash/flatten";

const getDefaultState = () => ({
  lastName: "Talbot",
  firstName: "Tom",
  position: "Senior Buyer",
  role: "employee",
  workplace: "DuPont",
  renderAs: "employee",
  roles: ["employee"],
  email: "",
  activeAssessments: [],
  closedAssessments: [],
  userId: null,
  platformVsHome: false,
});

const actions = {
  resetUserState({ commit }) {
    commit("resetState");
  },
  setPlatformVsHome({ commit }, { payload }) {
    commit("SET_PLATFORM_VS_HOME", payload);
  },
  switchToAdmin({ commit }) {
    commit("CHANGE_ROLE", "admin");
    commit("CHANGE_RENDER_VIEW", "admin");
  },
  switchToManager({ commit }) {
    commit("CHANGE_ROLE", "manager");
    commit("CHANGE_RENDER_VIEW", "manager");
  },
  setEmail({ commit }, { email }) {
    commit("SET_EMAIL", email);
  },
  switchToEmployee({ commit }) {
    commit("CHANGE_ROLE", "employee");
    commit("CHANGE_RENDER_VIEW", "employee");
  },
  switchToSeller({ commit }) {
    commit("CHANGE_ROLE", "seller");
    commit("CHANGE_RENDER_VIEW", "seller");
  },
  changeRenderView({ commit }, { role }) {
    commit("CHANGE_RENDER_VIEW", role);
  },
  setUserName({ commit }, { firstName, lastName }) {
    commit("SET_NAME", { firstName, lastName });
  },
  setUserId({ commit }, { id }) {
    commit("SET_ID", id);
  },
  setUserPosition({ commit }, { position }) {
    commit("CHANGE_POSITION", position);
  },
  setUserRoles({ commit }, { roles }) {
    const lowercaseRoles = roles.map((role) => {
      if (role === "CompanyAdmin") return "admin";
      if (role === "Manager") return "manager";
      if (role === "SellerAdmin") return "seller";
      return role.toLowerCase();
    });
    const importantRole = lowercaseRoles[0];
    commit("CHANGE_ROLE", importantRole);
    commit("CHANGE_RENDER_VIEW", importantRole);
    commit("SET_ROLES", lowercaseRoles);
  },
  getActiveAssessments({ commit, rootState }) {
    const calls = rootState.teams.teams_ids.map((id) =>
      fetchOngoingAssessments(id)
    );
    return Promise.all(calls).then((rsp) => {
      const data = rsp.map((el) => el.data.data);
      commit("SET_ACTIVE_ASSESSMENT", data);
    });
  },
  getAdminActiveAsessments({ commit }) {
    return fetchOngoingAdminAssessments().then((rsp) => {
      commit("SET_ACTIVE_ASSESSMENT", [rsp.data.data]);
    });
  },
  getCompanyAssessments({ commit }) {
    return fetchCompanyAssessments().then((rsp) => {
      commit("SET_CLOSE_ASSESSMENT", rsp.data.data);
    });
  },
  getClosedAssessments({ commit, rootState }) {
    const calls = rootState.teams.teams_ids.map((id) =>
      fetchClosedAssessments(id)
    );
    return Promise.all(calls).then((rsp) => {
      const data = flatten(
        rsp.filter((el) => el.data.data.length > 0).map((el) => el.data.data)
      ).sort(
        (a, b) =>
          new Date(b.closingTime).valueOf() - new Date(a.closingTime).valueOf()
      );
      commit("SET_CLOSE_ASSESSMENT", data);
    });
  },
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_PLATFORM_VS_HOME(state, payload) {
    state.platformVsHome = payload;
  },
  CHANGE_ROLE(state, role) {
    state.role = role;
  },
  CHANGE_RENDER_VIEW(state, role) {
    state.renderAs = role;
  },
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_ID(state, id) {
    state.userId = id;
  },
  CHANGE_POSITION(state, position) {
    state.position = position;
  },
  SET_EMAIL(state, email) {
    state.email = email;
  },
  SET_NAME(state, { firstName, lastName }) {
    state.firstName = firstName;
    state.lastName = lastName;
  },
  SET_ACTIVE_ASSESSMENT(state, assessments) {
    state.activeAssessments = assessments;
  },
  SET_CLOSE_ASSESSMENT(state, assessments) {
    state.closedAssessments = assessments;
  },
};

const getters = {
  getFullName: (state) => state.firstName + " " + state.lastName,
  getRole: (state) => state.renderAs,
  getBestRole: (state) => state.role,
  anySurveys: (state) => {
    let count = 0;
    state.activeAssessments.map((el) => (count += el.surveys.length));
    return count !== 0;
  },
  anyClosedSurveys: (state) => {
    return state.closedAssessments?.length > 0;
  },
  getClosedAssessmentsGroupedByTeam: (state, _, rootState) => {
    const mappedTeams =
      rootState.teams.teams &&
      rootState.teams.teams?.length > 0 &&
      rootState.teams.teams.map((team) => ({
        ...team,
        closedAssessments: state.closedAssessments?.filter(
          (el) => el.teamId === team.id
        ),
      }));
    return mappedTeams;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
